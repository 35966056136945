<template>
  <div class="ma-4">
    <v-toolbar class="mb-2">
      <v-btn v-if="!searchActive" icon @click="activateSearch">
        <v-icon>fal fa-search</v-icon>
      </v-btn>
      <v-autocomplete v-else :search-input.sync="studentSearch" :items="studentSearchItems" :filter="() => true" ref="studentSearchField" label="Student Search" outlined dense hide-details @change="(val) => $router.push('/chapel/student/' + val + '/' + term)" @blur="searchActive = false"></v-autocomplete>
      <v-img :src="photo || '/img/no.jpg'" height="64" contain class="mr-2" style="max-width:60px;" />
      <v-toolbar-title v-if="!searchActive">{{ name }} ({{ bannerId }}) Credits</v-toolbar-title>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" class="ml-3">
            {{ termString }}
            <v-icon right small>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="({ term, text, remaining, required }, index) in termList" :key="index" :to="'/chapel/student/' + bannerId + '/' + term">
            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
              <v-list-item-subtitle>{{ remaining }} credits remaining out of {{ required }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="showStudentSchedule">
            <v-icon>fal fa-calendar</v-icon>
            </v-btn>
        </template>
        <span>View Student's Class Schedule</span>
      </v-tooltip>
      <v-btn text to="/chapel/students">Back to List</v-btn>
    </v-toolbar>
    <v-row class="mb-2">
      <v-col cols="12" sm="6" lg="3">
        <v-list dense elevation="1">
          <v-subheader>Credits</v-subheader>
          <v-dialog v-model="editRequired" width="300">
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-title>Required</v-list-item-title>
                <v-list-item-action>{{ creditReq }}
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-card>
              <v-card-title>Edit Credits Required</v-card-title>
              <v-card-text>
                <v-text-field type="number" v-model="updCreditReq" label="Credits Required"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="editRequired = false">Cancel</v-btn>
                <v-btn text color="primary" @click="saveRequirement">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-list-item @click="filter=(filter === 'Received'? '' : 'Received')" :style="filter==='Received'?'background-color:#193264':''" :dark="filter==='Received'">
            <v-list-item-title>Received</v-list-item-title>
            <v-list-item-action>{{ creditRec }}</v-list-item-action>
          </v-list-item>
          <v-list-item @click="filter=(filter === 'Remaining'? '' : 'Remaining')" :style="filter==='Remaining'?'background-color:#193264':''" :dark="filter==='Remaining'">
            <v-list-item-title>Remaining</v-list-item-title>
            <v-list-item-action>{{ creditRem }}</v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <v-list dense elevation="1">
          <v-subheader>Credit Breakdown</v-subheader>
          <v-list-item v-for="{ text, value } in creditTypes" :key="text" @click="filter=(filter === text? '' : text)" :style="filter===text?'background-color:#193264':''" :dark="filter===text">
            <v-list-item-title>{{ text }}</v-list-item-title>
            <v-list-item-action>{{ value }}</v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <v-list dense elevation="1">
          <v-subheader>Housing</v-subheader>
          <v-list-item v-for="{ text, value } of housingDetails" :key="'housingDetails-' + text">
            <v-list-item-content>
              <v-list-item-subtitle>{{ text }}</v-list-item-subtitle>
              <v-list-item-title>{{ value }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <v-list dense elevation="1">
          <v-subheader>Status Details</v-subheader>
          <v-list-item v-for="{ text, value } of stuDetails" :key="'stuDetail-' + text">
            <v-list-item-content>
              <v-list-item-subtitle>{{ text }}</v-list-item-subtitle>
              <v-list-item-title>{{ value }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-data-table :items="filteredList" :headers="headers" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }">
      <template v-slot:item.date="{ item }">
        {{ stringFormatDate(item.date) }}
      </template>
      <template v-slot:item.action="{ item }">
        <review-summary v-if="item.summaryStatus === 'Pending'" :credit-entry="item" :stuid="id"></review-summary>
        <credit-edit v-else :action="item.credit > 0 ? 'remove' : 'add'" :chapel="item" :stuid="id" @updated="loadTerm"></credit-edit>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import CreditEdit from '@/components/chapel/creditEdit'
import ReviewSummary from '@/components/chapel/reviewSummary'
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'
import { sports } from '@/components/chapel/functions'

export default {
  components: {
    CreditEdit, ReviewSummary
  },
  setup (props, { root, refs }) {
    const bannerId = computed(() => root.$route.params.id)
    const term = computed(() => root.$route.params.term)
    const photo = ref(null)
    const name = ref('')
    const id = ref('')
    const termString = computed(() => term.value == null || term.value === '' ? '' : ((term.value.substr(-2) === '05' ? 'Spring ' : 'Fall ') + term.value.substr(0, 4)))
    const termList = ref([])
    const creditReq = ref(0)
    const updCreditReq = ref(0)
    const creditRec = ref(0)
    const creditRem = ref(0)
    const creditTypes = ref([])
    const housingDetails = ref([])
    const stuDetails = ref([])
    const chapels = ref([])
    const filter = ref('')
    const filteredList = computed(() => filter.value === '' ? chapels.value : chapels.value.filter(({ credit, type }) => filter.value === type || (filter.value === 'Received' && credit && credit > 0) || (filter.value === 'Remaining' && !credit)))

    watch(bannerId, () => {
      if (bannerId.value != null && bannerId.value !== '') {
        loadTerms()
        if (term.value != null && term.value !== '') loadTerm()
      }
    })
    watch(term, () => {
      if (term.value != null && term.value !== '' && bannerId.value != null && bannerId.value !== '') loadTerm()
    })

    const headers = ref([
      { text: 'Date', value: 'date' },
      { text: 'Speaker', value: 'speaker' },
      { text: 'Credit', value: 'credit' },
      { text: 'Credit Type', value: 'type' },
      { text: 'Reason', value: 'reason' },
      { text: 'Action', value: 'action' }
    ])

    const editRequired = ref(false)
    const required = ref(0)
    async function saveRequirement () {
      if (creditReq.value !== updCreditReq.value) {
        try {
          const data = await root.$feathers.service('chapel/student').patch(id.value, { required: updCreditReq.value })
          if (data.required === parseInt(updCreditReq.value)) {
            creditReq.value = data.required
            creditRec.value = data.credits.reduce((prev, { credit }) => prev + credit, 0)
            creditRem.value = data.required - creditRec.value
            editRequired.value = false
          } else {
            alert('There was an error saving the requirement. Please try again.')
          }
        } catch (e) {
          root.$store.dispatch('main/snackbar', { type: 'error', text: 'Error updating requirements: ' + e })
        }
      }
    }

    async function loadTerms () {
      try {
        const aggregate = [
          { $match: { bannerId: bannerId.value } },
          {
            $project: {
              term: 1,
              required: '$required',
              received: { $reduce: { input: '$credits', initialValue: 0, 'in': { $sum: '$credits.credit' } } }
            }
          },
          {
            $project: {
              term: 1,
              required: 1,
              received: 1,
              remaining: { $max: [ 0, { $subtract: ['$required', '$received'] } ] }
            }
          }
        ]
        const { data } = await root.$feathers.service('chapel/student').find({ query: { aggregate } })
        termList.value = data.map(({ term, required, received, remaining }) => { return { term, text: (term.substring(4, 6) === '05' ? 'Spring ' : 'Fall ') + term.substring(0, 4), required, received, remaining } }).reverse()
      } catch (e) {
        root.$store.dispatch('main/snackbar', { type: 'error', text: 'Error loading terms: ' + e })
      }
    }

    async function loadTerm () {
      try {
        root.$feathers.service('directory/people').find({ query: { bannerId: bannerId.value } }).then(({ data }) => {
          if (data.length > 0 && 'photo' in data[0]) {
            photo.value = data[0].photo
          }
        })
        const { data } = await root.$feathers.service('chapel/student').find({ query: { bannerId: bannerId.value, term: term.value } })
        if (data.length === 0) {
          root.$store.dispatch('main/snackbar', { type: 'error', text: 'No data found for the given student and term' })
          return
        }
        const stuRow = data[0]
        id.value = stuRow._id
        name.value = stuRow.name.preferred + ' ' + stuRow.name.last
        creditReq.value = stuRow.required
        // Load the chapels for the term
        const aggregate = [
          { $match: { term: term.value } },
          {
            $lookup: {
              from: 'Chapel-Students',
              localField: '_id',
              foreignField: 'credits.chapel',
              as: 'student',
              let: { 'chapel': '$_id' },
              pipeline: [
                { $match: { 'bannerId': bannerId.value, term: term.value } },
                { $unwind: { path: '$credits', includeArrayIndex: 'index' } },
                { $match: { $expr: { $eq: [ '$credits.chapel', '$$chapel' ] } } }
              ]
            }
          },
          { $project: { date: 1, speaker: 1, creditValues: '$credits', student: { $first: '$student' } } },
          {
            $project: {
              date: 1,
              speaker: 1,
              creditValues: 1,
              student: '$student._id',
              index: '$student.index',
              credit: '$student.credits.credit',
              type: '$student.credits.type',
              reason: '$student.credits.reason',
              summary: '$student.credits.summary',
              creditDate: '$student.credits.date'
            }
          },
          { $sort: { date: 1 } }
        ]
        const { data: chapelData } = await root.$feathers.service('chapel/schedule').find({ query: { aggregate } })
        chapels.value = chapelData
        const lastScanEntry = chapelData.filter(({ type }) => type === 'Swipe').sort(({ creditDate: aDate }, { creditDate: bDate }) => aDate < bDate ? 1 : -1)
        const lastScan = lastScanEntry.length > 0 ? lastScanEntry[0].creditDate : null
        creditRec.value = chapelData.filter(({ credit }) => credit != null).reduce((prev, { credit }) => prev + credit, 0)
        creditRem.value = creditReq.value - creditRec.value
        if (creditRem.value < 0) creditRem.value = 0
        // Build the arrays for the upper cards (creditTypes, housingDetails, and stuDetails)
        creditTypes.value = []
        const hash = {}
        chapelData.filter(({ type }) => type != null).forEach(({ type }) => { type in hash ? hash[type]++ : hash[type] = 1 })
        for (const text in hash) {
          creditTypes.value.push({ text, value: hash[text] })
        }
        housingDetails.value = []
        for (const field in stuRow.housing) {
          housingDetails.value.push({ text: field.substring(0, 1).toUpperCase() + field.substring(1), value: stuRow.housing[field] })
        }
        stuDetails.value = [
          { text: 'Class Level', value: (stuRow.classLevel === 'SR' ? 'Senior' : (stuRow.classLevel === 'JR' ? 'Junior' : (stuRow.classLevel === 'SO' ? 'Sophomore' : 'Freshman'))) },
          { text: 'Sports', value: stuRow.sports == null || stuRow.sports.length === 0 ? 'No Sports' : stuRow.sports.map((code) => sports.reduce((prev, { text, value }) => value === code ? text : prev, '')).join('; ') },
          { text: 'Last Scan', value: lastScan == null ? 'No Scans for selected term' : stringFormatDate(lastScan) }
        ]
      } catch (e) {
        root.$store.dispatch('main/snackbar', { type: 'error', text: 'Error loading student data: ' + e })
      }
    }
    onMounted(() => {
      root.$feathers.service('chapel/student').on('patched', (rec) => {
        if (rec.bannerId === bannerId.value) {
          if (rec.term === term.value) loadTerm()
          else loadTerms()
        }
      })
    })

    const searchActive = ref(false)
    const studentSearchField = ref(null)
    const studentSearch = ref('')
    const studentSearchItems = ref([])

    function activateSearch () {
      searchActive.value = true
      root.$nextTick(() => studentSearchField.value.focus())
    }

    watch(studentSearch, () => {
      if (studentSearch.value != null && studentSearch.value !== '') {
        const query = { term: term.value, $sort: { 'name.last': 1, 'name.preferred': 1 } }
        if (studentSearch.value.indexOf(',') >= 0) {
          let [last, preferred] = studentSearch.value.split(',')
          query['name.last'] = { $regex: last.trim(), $options: 'i' }
          query['name.preferred'] = { $regex: preferred.trim(), $options: 'i' }
        } else if (studentSearch.value.indexOf(' ') >= 0) {
          let [preferred, last] = studentSearch.value.replaceAll('  ', ' ').split(' ')
          query['name.last'] = { $regex: last.trim(), $options: 'i' }
          query['name.preferred'] = { $regex: preferred.trim(), $options: 'i' }
        } else {
          query.$or = [
            { 'name.last': { $regex: studentSearch.value, $options: 'i' } },
            { 'name.preferred': { $regex: studentSearch.value, $options: 'i' } }
          ]
        }
        root.$feathers.service('chapel/student').find({ query }).then(({ data }) => {
          studentSearchItems.value = data.map(({ bannerId, name: { last, preferred } }) => { return { value: bannerId, text: preferred + ' ' + last } })
        })
      } else {
        studentSearchItems.value = []
      }
    })

    function showStudentSchedule () {
      root.$store.commit('calendar/setTerm', term.value)
      root.$router.push('/calendar/schedule/' + bannerId.value)
    }

    return {
      bannerId,
      term,
      photo,
      name,
      id,
      termString,
      termList,
      creditReq,
      updCreditReq,
      creditRec,
      creditRem,
      creditTypes,
      housingDetails,
      stuDetails,
      chapels,
      filter,
      filteredList,
      headers,
      editRequired,
      required,
      saveRequirement,
      loadTerms,
      loadTerm,
      searchActive,
      studentSearchField,
      studentSearch,
      studentSearchItems,
      activateSearch,
      showStudentSchedule,
      stringFormatDate
    }
  }
}
</script>
