var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-4"},[_c('v-toolbar',{staticClass:"mb-2"},[(!_vm.searchActive)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.activateSearch}},[_c('v-icon',[_vm._v("fal fa-search")])],1):_c('v-autocomplete',{ref:"studentSearchField",attrs:{"search-input":_vm.studentSearch,"items":_vm.studentSearchItems,"filter":function () { return true; },"label":"Student Search","outlined":"","dense":"","hide-details":""},on:{"update:searchInput":function($event){_vm.studentSearch=$event},"update:search-input":function($event){_vm.studentSearch=$event},"change":function (val) { return _vm.$router.push('/chapel/student/' + val + '/' + _vm.term); },"blur":function($event){_vm.searchActive = false}}}),_c('v-img',{staticClass:"mr-2",staticStyle:{"max-width":"60px"},attrs:{"src":_vm.photo || '/img/no.jpg',"height":"64","contain":""}}),(!_vm.searchActive)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.name)+" ("+_vm._s(_vm.bannerId)+") Credits")]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.termString)+" "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("fal fa-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.termList),function(ref,index){
var term = ref.term;
var text = ref.text;
var remaining = ref.remaining;
var required = ref.required;
return _c('v-list-item',{key:index,attrs:{"to":'/chapel/student/' + _vm.bannerId + '/' + term}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(remaining)+" credits remaining out of "+_vm._s(required))])],1)],1)}),1)],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.showStudentSchedule}},on),[_c('v-icon',[_vm._v("fal fa-calendar")])],1)]}}])},[_c('span',[_vm._v("View Student's Class Schedule")])]),_c('v-btn',{attrs:{"text":"","to":"/chapel/students"}},[_vm._v("Back to List")])],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-list',{attrs:{"dense":"","elevation":"1"}},[_c('v-subheader',[_vm._v("Credits")]),_c('v-dialog',{attrs:{"width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-title',[_vm._v("Required")]),_c('v-list-item-action',[_vm._v(_vm._s(_vm.creditReq)+" ")])],1)]}}]),model:{value:(_vm.editRequired),callback:function ($$v) {_vm.editRequired=$$v},expression:"editRequired"}},[_c('v-card',[_c('v-card-title',[_vm._v("Edit Credits Required")]),_c('v-card-text',[_c('v-text-field',{attrs:{"type":"number","label":"Credits Required"},model:{value:(_vm.updCreditReq),callback:function ($$v) {_vm.updCreditReq=$$v},expression:"updCreditReq"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.editRequired = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveRequirement}},[_vm._v("Save")])],1)],1)],1),_c('v-list-item',{style:(_vm.filter==='Received'?'background-color:#193264':''),attrs:{"dark":_vm.filter==='Received'},on:{"click":function($event){_vm.filter=(_vm.filter === 'Received'? '' : 'Received')}}},[_c('v-list-item-title',[_vm._v("Received")]),_c('v-list-item-action',[_vm._v(_vm._s(_vm.creditRec))])],1),_c('v-list-item',{style:(_vm.filter==='Remaining'?'background-color:#193264':''),attrs:{"dark":_vm.filter==='Remaining'},on:{"click":function($event){_vm.filter=(_vm.filter === 'Remaining'? '' : 'Remaining')}}},[_c('v-list-item-title',[_vm._v("Remaining")]),_c('v-list-item-action',[_vm._v(_vm._s(_vm.creditRem))])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-list',{attrs:{"dense":"","elevation":"1"}},[_c('v-subheader',[_vm._v("Credit Breakdown")]),_vm._l((_vm.creditTypes),function(ref){
var text = ref.text;
var value = ref.value;
return _c('v-list-item',{key:text,style:(_vm.filter===text?'background-color:#193264':''),attrs:{"dark":_vm.filter===text},on:{"click":function($event){_vm.filter=(_vm.filter === text? '' : text)}}},[_c('v-list-item-title',[_vm._v(_vm._s(text))]),_c('v-list-item-action',[_vm._v(_vm._s(value))])],1)})],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-list',{attrs:{"dense":"","elevation":"1"}},[_c('v-subheader',[_vm._v("Housing")]),_vm._l((_vm.housingDetails),function(ref){
var text = ref.text;
var value = ref.value;
return _c('v-list-item',{key:'housingDetails-' + text},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(text))]),_c('v-list-item-title',[_vm._v(_vm._s(value))])],1)],1)})],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-list',{attrs:{"dense":"","elevation":"1"}},[_c('v-subheader',[_vm._v("Status Details")]),_vm._l((_vm.stuDetails),function(ref){
var text = ref.text;
var value = ref.value;
return _c('v-list-item',{key:'stuDetail-' + text},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(text))]),_c('v-list-item-title',[_vm._v(_vm._s(value))])],1)],1)})],2)],1)],1),_c('v-data-table',{attrs:{"items":_vm.filteredList,"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [5, 10, 15, 20] }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.stringFormatDate(item.date))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.summaryStatus === 'Pending')?_c('review-summary',{attrs:{"credit-entry":item,"stuid":_vm.id}}):_c('credit-edit',{attrs:{"action":item.credit > 0 ? 'remove' : 'add',"chapel":item,"stuid":_vm.id},on:{"updated":_vm.loadTerm}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }